<template>
    <b-row>
        <b-col cols="12" v-if="has_mounted">
                <Table
                    :columns="columns"
                    :simple_columns="simple_columns"
                    :uid="table_uid"
                    :ref="table_uid"
                    :data="workGroups_data"
                    :table_mode="table_view_mode"
                    :default_values="default_values"
                    :is_loading="is_loading"
                    prevent_first_row_select
                    :button_methods="button_methods"
                    :active_id="selected_team_id"
                    :validation="table_validation_rules"
                    :context_menu_items="['View', 'Edit', 'Delete']"
                    @selectedRowEvent="selectedRowEvent"
                    @saveTable="saveTable"
                    @deleteRows="deleteRow"
                    hide_action_buttons
                    allow_select
                    :edit_permissions="{manager:'group-members'}"
                    :required_permissions="['group-members']"
                    guideline_key="work_groups_table"
                >
                <template #title>
                    {{ titleName }}
                    &nbsp;>&nbsp;
                    <span class="text-primary">{{ headerLevelName }}</span>
                </template>
                <template #buttons>
                    <b-button size="sm" variant="primary" @click="showGroupsModal({})" class="me-1" style="display: inline-block">
                        <font-awesome-icon :icon="['fas', 'fa-plus']" />
                    </b-button>
                    <b-button v-show="selected_team_id != -1" @click="resetSelection()" variant="primary" size="sm" class="me-2">
                        <font-awesome-icon icon="fa-plus" class="me-1 fa-lg"/>
                        Reset Selection
                    </b-button>
                </template>
                </Table>
                <HierarchyModal v-if="show_level_linking_modal" show_all_levels :payload="modal_payload" @selectedNewID="handleRowLevelsUpdate" @updatedNewID="handleRowLevelsUpdate"/>
                <workGroupModal :payload="modal_payload" v-if="show_groups_modal" @saveTable="saveTable" />
        </b-col>
        <b-col cols="12">
        </b-col>
    </b-row>
</template>

<script>
import Table from '@/components/widgets/table/table.vue'
import { levelComputed, levelMethods ,peopleComputed, peopleMethods, profileComputed, profileMethods } from '@/state/helpers'
import HierarchyModal from '@/components/objectives/components/misc/hierarchyModal.vue'
import workGroupModal from './profileModals/workGroupModal.vue';
import { format, parseISO } from 'date-fns';
import _ from 'lodash'
import Swal from 'sweetalert2'
export default {
    name: 'workGroups',
    components: {
        Table,
        HierarchyModal,
        workGroupModal
    },
    props:{
        initial_table_mode:{
            default: 'view',
        },
        noEdit:{
            boolean: false,
        }
    },
    data:()=>({
        //* vars relating to the table component
        table_uid: 'workGroups',
        table_view_mode: 'view',
        is_loading: false,
        selectedEdit: false,
        has_mounted: false, 
        //* active_id should be a computed property that returns the currently selected item from your vuex store
        active_id: -1,
        changes_made_flag: false,
        delete_array:[],  
        show_level_linking_modal: false,
        show_groups_modal: false,
        modal_payload: {}
    }),
    computed:{
        ...levelComputed,
        ...peopleComputed,
        ...profileComputed,

        list_of_users(){
        // * map all_users from the levels vuex store to the format expected from webix
        return this.levelEmployeesData.map( user => {
            return { id: user.id, value: user.name, email: user.email }
        })
        },
        //* table computed methods
        columns(){
            let el = this
            //* get ref of current scope
            let workGroups_columns = [
                { id: 'name', header: 'Group Name', fillspace: 3, editor: 'text'},
                { id: 'lead_user', editor: 'combo', minWidth: 120, options: this.list_of_users,
                    header: 'Group Leader',
                    tooltip: '',
                    suggest:{
                        view:"suggest",
                        filter:function(item, value){
                            let index = _.findIndex(el.levelEmployeesData, {id: value });
                            let name = '';
                            if(index != -1){
                                name = el.levelEmployeesData[index].name;
                            }
                            if(item.includes(name)){
                                return true;
                            }
                            else{
                                return false;
                            }
                        },
                        body:{
                            view:"list",
                            data:this.list_of_users,
                            template:"#value# - #email#",
                            yCount:10
                        }
                    },
                },
                {
                    id: "level_id",
                    //editor: "combo",
                    header: 'Linked Level',
                    sort: "text",
                    width: 250,
                    template:function(obj){
                        console.log(obj)
                        if(obj.level_id != undefined){
                            let index = _.findIndex(el.levels, {id: obj.level_id });
                            let level_name = '';
                            if(index != -1){
                                level_name = el.levels[index].name;
                            }
                            if(level_name != '') {
                                return `<span class="show_hierarchy_text"><i class="me-1 show_hierarchy_modal fas fa-pen"></i>${level_name}</span>`
                            } else {
                                return `<span class="show_hierarchy_text"><i class="me-1 show_hierarchy_modal fas fa-pen"></i>Link Level</span>`
                            }
                            
                        }
                        else{
                            if(!obj.is_empty) {
                                return `<span class="show_hierarchy_text"><i class="me-1 show_hierarchy_modal fas fa-pen"></i>Link Level</span>`
                            } else {
                                return ''
                            }
                            
                        }
                    },
                },
                { id: 'created_date', header: 'Created Date', fillspace: 1, visibility: ['view','delete'],
                    //* example on how to format date without mutating the origional data
                    format:function(value){
                        let date = value;
                        if(date !== ''){
                            if(typeof date === 'string'){
                                date = parseISO(date);
                            }
                            return format(date, 'dd-MMM-yyyy');
                        }
                        else{
                            return '';
                        }
                    }
                },
            ];
            return workGroups_columns;
        },
        simple_columns(){
            let el = this
            //* get ref of current scope
            let workGroups_columns = [
                { id: 'name', header: 'Group Name', fillspace: 3, editor: 'text'},
                { id: 'lead_user', header: 'Group Leader', editor: 'combo', minWidth: 120,
                template:function(obj){
                    console.log(obj)
                        if(obj.lead_user != undefined){
                            let index = _.findIndex(el.levelEmployeesData, {id: obj.lead_user });
                            let name = '';
                            if(index != -1){
                                name = el.levelEmployeesData[index].name;
                            }

                            return `<span>${name}</span>`
                        }
                        else{
                            return ''
                        }
                    },
                },
                {
                    id: "level_id",
                    //editor: "combo",
                    header: 'Linked Level',
                    sort: "text",
                    width: 250,
                    template:function(obj){
                        if(obj.level_id != undefined){
                            let index = _.findIndex(el.levels, {id: obj.level_id });
                            let level_name = '';
                            if(index != -1){
                                level_name = el.levels[index].name;
                            }

                            if(level_name != '') {
                                return `<span>${level_name}</span>`
                            }
                        }
                        else{
                            return ''
                        }
                    },
                },
                { id: 'created_at', header: 'Created Date', fillspace: 1, visibility: ['view','delete'],
                    //* example on how to format date without mutating the origional data
                    format:function(value){
                        console.log(value)
                        let date = value;
                        if(date !== ''){
                            if(typeof date === 'string'){
                                date = parseISO(date);
                            }
                            return format(date, 'dd-MMM-yyyy');
                        }
                        else{
                            return '';
                        }
                    }
                },
            ];
            return workGroups_columns;
        },

        titleName() {
            return 'Work Groups'
        },  
        headerLevelName() {
            return this.selected_level.name
        },
        workGroups_data() {
            if(this.teams.teams == undefined) {
                return []
            } else {
                let teams_list = this.teams.teams
                if(this.selected_user_id) {
                    let filtered_list = []
                    if(teams_list.length != 0) {
                        teams_list.forEach(team => {
                            team.team_members.forEach(user => {
                                if(user.user_id == this.selected_user_id) {
                                    filtered_list.push(team)
                                }
                            })
                        })
                    }
                    return this.processTableData(filtered_list);
                } else {
                    return this.processTableData(this.teams.teams);
                }
                

                
            }
        },
        default_values(){
            let el = this;
            return [    
                { id : "level_id", value: el.selected_level.id },
            ]
        },
        table_validation_rules(){
            return {
                "name": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === ''){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "lead_user": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                },
                "level_id": function(item, obj){
                    if(obj.is_empty){
                        return true;
                    }
                    else{
                        if(item === '' || item == undefined){
                            return false;
                        }
                        else{
                            return true;
                        }
                    }
                }
            };
        },
        button_methods(){
            let el = this;
            let onClick = {
                'show_hierarchy_modal':function(e, id){
                    let item = this.getItem(id)
                    el.showHierarchyModal(item);
                },
                'edit_action':function(e, id){
                    let item = this.getItem(id)
                    el.showGroupsModal(item)
                },
            }
            return onClick;
        },
    },
    methods:{
        ...profileMethods,
        ...peopleMethods,
        ...levelMethods,
        showGroupsModal(item){
            this.modal_payload = _.cloneDeep(item);
            this.show_groups_modal = true;
            this.$nextTick(()=>{
                this.$bvModal.show('workGroupModal');
                this.$root.$once("bv::modal::hidden", (event) => {
                    if (event.type == "hidden" && event.componentId == "workGroupModal") {
                        this.show_groups_modal = false;
                        this.modal_payload = null;
                    }
                });
            })
        },
        handleRowLevelsUpdate(payload){
            let table = window.webix.$$(this.table_uid);
            let item = table.getItem(this.modal_payload.item_id);
            item['level_id'] = Number(payload);
            table.updateItem(this.modal_payload.item_id, item)
            this.$bvModal.hide('hierarchyModal');
        },
        resetSelection() {
            this.setSelectedTeam(-1);
        },
        processTableData(data) {
            const tableData = [];
           
            if (data == null) {
              data = [];
            }
            else {
                // const id = 0;
                data.forEach((element) => {
                    const team = {};
                    if (element.team_members.length != 0) {
                        let team_members = element.team_members;
                        team['lead_user'] = team_members[0].user_id
                        team['team_members'] = team_members
                        team['id'] = element.id;
                        team['name'] = element.name;
                        team['level_id'] = element.level_id
                        team['created_at'] = element.created_at
                        tableData.push(team);
                    }
                });
            }
            this.people_data = tableData;
            this.is_loading = false;
            return tableData;
        },
        //* table event handlers
        selectedRowEvent(row){
            console.log(row)
            if(!row){
            // set selected_team_id to -1
                this.setSelectedTeam(-1)
                return;
            }
            else{
                this.setSelectedTeam(row.id)
            }
        },

        saveTable(data){
            console.log(JSON.parse(JSON.stringify(data)))
            this.is_loading = true;
            let rows_to_save = [];
            let updatedMembers = []
            let hiddenLeadUser = false
            let membersArray = data[0].team_members;
            if(membersArray != null) {
                membersArray.forEach(member => {
                    let lead = {}
                    if(Number(member.id) == Number(data[0].user_id)) {
                        hiddenLeadUser = true
                        lead = { 'user_id': member.user_id, 'lead_user': true };
                    } else {
                        lead = { 'user_id': member.user_id, 'lead_user': false };
                    }
                    updatedMembers.push(lead);
                });
            }

            if(!hiddenLeadUser) {
                //Show alert
                let newlead = { 'user_id': data[0].user_id, 'lead_user': true };
                updatedMembers.push(newlead);
            }
            data.forEach( row => { 
                if(!row.is_empty){
                    let newRow = row.is_new;
                    var params = {
                        id: newRow ? -1 : row.id,
                        level_id: row.level_id,
                        name: row.name,
                        members:  updatedMembers,
                    };
                    rows_to_save.push(params);
                }
            })

            let params = {
                teams: rows_to_save,
            }
            this.is_loading = true;
            this.saveLevelTeams(params).then(()=>{
                    this.loadTeams(this.selected_level.id)
                    .then(()=>{
                        this.setSelectedTeam(this.selected_team_id)
                        this.is_loading = false;
                    })
                })
        },
        onDeleteArrayUpdate(array){
            //* update array of items that are selected for delete
            this.delete_array = array;
        },
       
        showDiscardPrompt(type){
            //* type is the view mode the user is trying to switch to
            Swal.fire({
                title: "Clear unsaved changes?",
                text: "Warning, changes will be deleted.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    //* If user selects yes
                    this.table_view_mode = type;
                }
            });
        },
        deleteRow(data) {
            let promises = [];
            data.forEach( item => {
                promises.push(this.deleteTeams({id: item.id}));
            })
            Promise.all(promises)
            .then(()=>{
                this.loadTeams(this.selected_level.id)
                .then(()=>{
                    this.$refs[this.table_uid].table_mode = 'view';
                })
			})
        },
    },

    beforeMount(){
        this.table_view_mode = this.initial_table_mode;
    },
    mounted(){
        this.table_uid = `${this.table_uid}-${this.$route.name}`;
        this.has_mounted = true;        
    },
}
</script>

<style>
.top-heading {
    width: 100%;
    display: inline-flex;
    justify-content: space-between;
}
</style>