<template>
  <div>
    <b-card class="m-1">
              <div class="d-flex">
                  <div id="chart" style="margin: auto;">
                     <apexchart v-if="has_loaded" type="radar" height="400" width="500" :options="chartOptions" :series="series"></apexchart>
                  </div>
              </div>
      </b-card>
  </div>
</template>

<script>

import { ganttComputed, ganttMethods, peopleComputed, peopleMethods, levelComputed } from "@/state/helpers.js";

import ApexCharts from "vue-apexcharts";
export default {
  components: {
    apexchart: ApexCharts,
  },
  props: {
    scorecard: {
      default: false
    }
  },
  data:() => ({
        has_loaded:false,
        series: [{
          name: 'User',
          data: [],
        }, {
          name: 'Job',
          data: [],
        }, 
        ],
        chartOptions: {
          chart: {
            height: 800,
            width: 800,
            type: 'radar',
            dropShadow: {
              enabled: true,
              blur: 1,
              left: 1,
              top: 1
            }
          },
          plotOptions: {
            radar: {
              size: 150,
              polygons: {
                strokeColors: '#ced4da',
                connectorColors: '#ced4da',
                fill: {
                  colors: ['#f8f8f8', '#fff']
                } 
              }
            }
          },
          title: {
            text: 'Competence Radar Chart'
          },
          stroke: {
            width: 2
          },
          fill: {
            opacity: 0.1
          },
          markers: {
            size: 3
          },
          legend: {
            show: true,
            customLegendItems: ['User', 'Job'],
            style: {
              fontSize: "10"
            }
          },
          tooltip: {
              enabled: true,
              x: {
                  formatter: (value) => value
              }
          },
          xaxis: {
            categories: [],
            labels: {
              show: true,
              style: {
                colors: ["#000000"],
              },
              formatter: (value) => {
                var len = value.length
                return len > 12 ?
                 value.substr(0, 12) + '...' : value
              },
            },
          },
          yaxis: {
            tickAmount: 10,
            min: 0,
            max: 10,
            text: {
              fill: ["#000000"]
            },
            labels: {
              show: true,
              style: {
                colors: ["#000000"],
                fill: ["#000000"]
              }
            },
          },
        }
    }),
    watch: {
      employeeDetails() {
        this.has_loaded =false
        this.$nextTick(() => {
          this.setActiveJobOfEmployee() 
        })
      }
    },
    computed:{
        ...ganttComputed,
        ...peopleComputed,  
        ...levelComputed
    },
    methods:{
        ...ganttMethods,
        ...peopleMethods,
        setActiveJobOfEmployee() {
          if(this.employeeDetails.current_job.id) {
            this.setActiveJobId(this.employeeDetails.current_job.id).then(() => {
              this.buildChartBase()
            });
          } else {
            //
            if(this.employeeDetails.current_job.user_skills) {
              this.buildChartBase()
            }
          }
        },
        buildChartBase() {
          if(this.activeJobId == -1 || this.companyJobs.length == 0){
                return [];
            } else {
                let names = []
                let job_values = []
                let user_values = []
                let categories_ids = [];
                //let categories_names = []
                  if(this.employeeDetails.current_job.user_skills.length != 0 && this.employeeDetails.current_job.skills.length != 0) {
                    let job_skills = this.employeeDetails.current_job.skills
                     job_skills.forEach(job_skill => {
                         names.push(job_skill.skill_name)
                         job_values.push(job_skill.competency_required);
                         categories_ids.push(job_skill.category_id)
                     })
                     let user_skills = this.employeeDetails.current_job.user_skills
                     user_skills.forEach(user_skill => {
                         user_values.push(user_skill.user_competency_rating)
                     })

                     this.series[0].name = "Team Member - " + this.employeeDetails.name
                    this.chartOptions.legend.customLegendItems[0] = this.series[0].name
                    this.series[1].name = "Job Profile - " + this.employeeDetails.current_job.title
                    this.chartOptions.legend.customLegendItems[1] = this.series[1].name
                    this.series[0].data = user_values
                    this.series[1].data = job_values
                    this.chartOptions.xaxis.categories = names
                    this.$nextTick(() => {
                      this.has_loaded= true
                  })
                  } else {
                    return []
                  }
                  
            }
        }  
    },
    beforeDestroy(){
    },
    mounted(){
      if(this.employeeDetails == null) {
          this.loadEmployeeData(this.selected_user_id).then(()=> {
            this.setActiveJobOfEmployee()
          })
        } else {
          this.setActiveJobOfEmployee()
        }
    },
}
</script>

<style>
.table-styles {
  /* border: 1px solid black; */
  width: 100px
}

.apexcharts-xaxis text {
  fill: #74788d;
}
.apexcharts-yaxis text {
  fill: #74788d;
}
.apexcharts-legend-text {
  font-size: 0.75rem !important;
}
</style>