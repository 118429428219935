<!--
@Author: Calvin Green <calvin>
@Date:   2021-12-14T11:24:47+02:00
@Email:  calvin@diversitude.com
@Last modified by:   calvin
@Last modified time: 2021-12-16T08:05:23+02:00
@Copyright: Diversitude 2021
-->

<template>
    <Layout>
        <div v-if="hiddenOrgChart">
            <OrganisationChart/>
        </div>
        <div v-if="!hiddenOrgChart">
            <b-card class="mt-1" v-if="selected_user_id != null && has_loaded == true">
                <userProfileHeaderVue :activeEmployeeId="selected_user_id" />
            </b-card>
            <b-card v-if="hasRequiredPermissions == false">
                <b-alert show >Permission not found</b-alert>
            </b-card>
            <b-card v-if="hasRequiredPermissions">
                <!-- fix components trying to display data when data hasn't been loaded yet -->
                <b-tabs v-if="has_loaded == true && selected_user_id != null" content-class="mt-2" v-model="tabIndex" style="font-size: 0.7rem !important;">
                    <b-tab title="Profile" lazy>
                        <!-- <AllUsers v-if="show_tab_component"></AllUsers> -->
                        <b-overlay :show="is_loading_profiles">
                            <Employees />
                        </b-overlay>
                    </b-tab>
                    <b-tab v-if="selected_user_id != null && has_loaded == true" title="Account Details" lazy>
                        <editDetails :activeEmployeeId="selected_user_id" />
                    </b-tab>
                    <!-- <b-tab v-if="$hasPerm('people-jobs-configuration')" ref="jobTab" title="Job Configuration" lazy>
                        <JobsTab></JobsTab>
                    </b-tab> -->
                    <b-tab v-if="isDevEnv()" title="Hiring" lazy >
                        <Hiring></Hiring>
                    </b-tab>
                    <b-tab v-if="isDevEnv()"  title="Onboarding" lazy>
                        <Onboarding></Onboarding>
                    </b-tab>
                    <b-tab v-if="isDevEnv()"  title="Offboarding" lazy>
                        <Offboarding></Offboarding>
                    </b-tab>
                    <b-tab v-if="isDevEnv()"  title="Team Performance" lazy>
                        <Performance></Performance>
                    </b-tab>
                    <b-tab v-if="selected_user_id != null && has_loaded == true" lazy title="Human Resources">
                        <humanResources :activeEmployeeId="selected_user_id" />
                    </b-tab>
                    <b-tab v-if="selected_user_id != null && has_loaded == true" lazy title="Permissions">
                        <permissions :activeEmployeeId="selected_user_id" />
                    </b-tab>
                    <b-tab v-if="selected_user_id != null && has_loaded == true" lazy title="Teams">
                        <teams :activeEmployeeId="selected_user_id" />
                    </b-tab>
                    <b-tab v-if="selected_user_id != null && has_loaded == true" lazy title="Competence">
                        <competency :activeEmployeeId="selected_user_id" />
                    </b-tab>
                    <b-tab v-if="selected_user_id != null && has_loaded == true" lazy title="Skills">
                        <skills :activeEmployeeId="selected_user_id" />
                    </b-tab>
                </b-tabs>
                <Employees v-if="selected_user_id == null"/>
                <b-alert v-if="has_loaded == false" show>
                    <h5>
                        <font-awesome-icon icon="fa-circle-notch" class="mt-1 fa-spin"/>
                        &nbsp;
                        Loading People Data
                    </h5>
                </b-alert>
                <b-button v-if="false" @click="handleNewJobSelection">Test Job tab</b-button>
            </b-card>
        </div>
    </Layout>
</template>

<script>
import Layout from "../../layouts/main";
// import AllUsers from "@/components/people/all-users.vue";
import Employees from "@/components/people/employees.vue";
import Hiring from '@/components/people/hiring.vue'
import Onboarding from '@/components/people/onboarding.vue';
import Offboarding from '@/components/people/offboarding.vue';
import Performance from '@/components/people/team-performance.vue';
import userProfileHeaderVue from "@/components/people/components/employees/userProfileHeader.vue";
import editDetails from "@/components/people/components/editDetails.vue";
import humanResources from "@/components/people/components/humanResources.vue";
import permissions from "@/components/people/components/permissions.vue";
import teams from "@/components/people/components/teams.vue"
import competency from "@/components/people/components/competency.vue"
import skills from "@/components/people/components/skills.vue"
import OrganisationChart from '@/components/widgets/graphs/organisation-chart.vue'
import {
  levelComputed,
  peopleMethods,
  peopleComputed,
  profileComputed,
  profileMethods,
  webixTableState,
  menuMethods,
} from "@/state/helpers";
export default {
    name: "Admin",
    components: {
        Layout,
        // AllUsers,
        Employees,
        Hiring,
        Onboarding,
        Offboarding,
        //HierarchyChart,
        Performance,
        userProfileHeaderVue,
        editDetails,
        humanResources,
        permissions,
        teams,
        OrganisationChart,
        competency,
        skills
    },
    data:() => ({
        //* set the top most company id for the current level
        //company_level_id: null,
        //* loading boolean
        selected_user: null,
        has_loaded: false,
        is_loading_profiles: false,
        show_tab_component: true,
        tabIndex: 1,
        show_org_chart: false,
        title: "People",
        items: [
            {
                text: "Home",
                href: "/",
            },
            {
                text: "People",
                active: true,
            },
        ],
    }),
    computed: {
        ...levelComputed,
        ...peopleComputed,
        ...profileComputed,
        ...webixTableState,
        hasRequiredPermissions(){
            if(this.$hasPerm('people') || this.$hasPerm('people-manager')){
                return true;
            }
            return false;
        },
        activeTab(){
            if(this.selected_user_id == null){
                return 'jobs';
            }
            else{
                return 'profile';
            }
        },
    },
    watch: {
        company_id(){
            //Update jobs list for company id change
            this.loadCompanyJobs(this.company_id);
        },
        selected_level() {
            //* resolve the hiringData and employeesData together
            if(this.selected_level != null && this.has_level_tree_loaded && this.has_loaded){
                //this.setCompanyLevelId()
                //.then(()=>{
                    this.loadDataAsPromise();
                //})
            }
        },
        current_level_id(oldValue, newValue){
            if(oldValue == newValue){
                console.log('Selected Same level')
            }
            else{
                this.show_tab_component = false;
                this.$nextTick(()=>{
                    this.show_tab_component = true;
                })
            }
        },
        // selected_user_id() {
        //     if(this.selected_user_id != null) {

        //     }
        // }
        has_level_tree_loaded(newValue){
            //* init top level id after tree has rendered
            if(newValue && this.allow_tree_selection == false && this.is_loading_profiles == false){
                //this.setCompanyLevelId()
                //.then(()=>{
                    this.loadDataAsPromise();
                //})
            }
        },
        selected_user_id() {
            if(this.employeeDetails == null && this.selected_user_id != -1) {
                this.loadDataAsPromise();
            }
            if(this.selected_user_id == -1) {
                this.tabIndex = 0
            }   
        }
    },
    methods:{
        ...peopleMethods,
        ...profileMethods,
        setLoading: menuMethods.setLoading,
        debugMe(){
            let row = {
                id: -1,
                status: 'hired',
                first_name: 'Save Test',
                middle_name: 'empty',
                last_name: 'empty',
                source: 'Internet',
                email: 'email@email.com',
                current_salary: 0,
            }
            let params = {
                hiring_position_id: 1,
                candidates: [row]
            }
            this.saveJobCandidate(params);
        },
        loadDataAsPromise(){
            this.is_loading_profiles = true;
            //* load component data and then set a flag to display components
            let promises = [
                this.loadEmployeesData(this.selected_level.id),
                this.loadTeams(this.selected_level.id)
            ];
            Promise.all(promises)
            .then(()=>{
                this.has_loaded = true;
                this.is_loading_profiles = false;
            })
            .catch(()=>{ this.is_loading_profiles = false; })
        },
        // eslint-disable-next-line
        handleNewJobSelection(new_id){
            this.setActiveJobId(new_id);
            this.$refs.jobTab.activate();
        },
        // setCompanyLevelId(){
        //     return new Promise( resolve => {
        //         this.$nextTick(()=>{
        //             const tree = window.webix.$$('levelsTree');
        //             let item = tree.getItem(this.selected_level.id);
        //             let top_level_id = -1;
        //             if(item.parent_id === null){
        //                 //* current level is company level
        //                 top_level_id = item.id;
        //             }
        //             else{
        //                 top_level_id = tree.getParentId(this.selected_level.id);
        //                 let found = false;
        //                 while(!found){
        //                     let new_parnet_id = tree.getParentId(top_level_id);
        //                     if(new_parnet_id === 0){
        //                         found = true;
        //                     }
        //                     else{
        //                         top_level_id = new_parnet_id;
        //                     }
        //                 }
        //             }
        //             this.company_level_id = top_level_id;
        //             resolve();
        //         })
        //     })
        // }
    },
    mounted(){
        this.loadSkillCategories()
        //*used to display loading state in router menu
        setTimeout(()=>{
            this.setLoading(false);
        }, 500)    
        
        if (this.selected_level && this.selected_level.id && this.has_level_tree_loaded) {
            this.tabIndex = 0
            //this.setCompanyLevelId()
            //.then(()=>{
                this.loadDataAsPromise();
            //})
        }
        this.$eventHub.$on('openNewlyCreatedJob', this.handleNewJobSelection);
    },
    beforeDestroy(){
        this.$eventHub.$off('openNewlyCreatedJob');
        if(this.selected_level){
            this.loadEmployeesData(this.selected_level.id)
        }
        this.hideOrgChart(false)
    }

}
</script>

<style>

</style>
