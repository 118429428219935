<template>
    <b-modal id="workGroupModal" centered title="Add Work Group" size="lg" hide-footer>
        <b-form @submit.prevent>
            <div class="container">
                <div class="row">
                    <div class="col-sm p-0">
                        <b-form-group class="label" id="input-group-1" label="Friction Name:" label-for="input-1" size="sm">
                            <b-form-input
                            id="input-1"
                            size="sm"
                            v-model="params.name"
                            required
                            ></b-form-input>
                        </b-form-group>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-sm p-0" style="margin-left: 4px;">
                        <b-form-group id="input-group-2" label="Organisation Structure:" label-for="input-2" size="sm" >
                        <b-row>
                            <span @mouseover="onOverItem('levelsDropdown', true)" @mouseleave="onOverItem('levelsDropdown', false)" >
                                <b-dropdown id="dropdownTree" ref="levelsDropdown" no-caret :text="getLevelForID" variant="ouline" size="sm" class="dropdownStyles dropdown-toggle-no-carret">
                                    <span class="dropdown-item dropdownItemStyles dropdown-toggle-no-carret" no-caret @click.stop="" href="#"><HierarchyTree class="dropdown-toggle-no-carret" v-if="show_levels_tree" no-caret @selectedNewID="handleLevelUpdateEvent" :payload="levels_tree_payload" show_all_levels /></span>
                                </b-dropdown>
                            </span>
                        </b-row>
                        </b-form-group>
                    </div>
                    <div class="col-sm p-0" style="margin-left: 4px;">
                        <b-form-group id="input-group-3" label="Owner:" label-for="input-3" size="sm" >
                            <vue-typeahead-bootstrap
                                ref="userSearch"
                                :data="users"
                                size="sm"
                                v-model="owner_query"
                                placeholder="Search name/email"
                                @hit="selectedOwner"
                                showOnFocus>
                            ></vue-typeahead-bootstrap>
                        </b-form-group>
                    </div>
                </div>
            </div>
            <div class="text-end pt-2 mt-3">
                <b-button variant="light" @click="$bvModal.hide('workGroupModal')">Close</b-button>
                <b-button @click="save()" variant="success" class="ms-1">Save</b-button>
            </div>
        </b-form>
        <HierarchyModal @selectedNewID="handleLevelUpdateEvent" :payload="modal_payload" show_all_levels v-if="show_level_linking_modal" />
        <ObjectiveModal v-if="show_objective_modal" :not_objective="true" :objective_type="'frictionAndRisks'" :payload="modal_payload" @selectedNewID="handleObjectiveUpdateEvent" @updateNewID="handleObjectiveUpdateEvent"/>
    </b-modal>
</template>

<script>
import { levelComputed, peopleMethods, peopleComputed, profileComputed } from "@/state/helpers";
import HierarchyModal from '@/components/objectives/components/misc/hierarchyModal.vue'
import ObjectiveModal from '../../../objectives/components/misc/objectiveModals/linkedObjectiveModal.vue'
import HierarchyTree from "../../../objectives/components/misc/dropdownComponents/hierarchyTree.vue";
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap'
// /import _ from 'lodash'
export default {
    components:{
        HierarchyModal,
        ObjectiveModal,
        HierarchyTree,
        VueTypeaheadBootstrap
    },
    props:{
        payload:{
            require: false
        },
    },
    data:() => ({
        params:{
            id: -1,
            name:'',
            user_id: null,
            level_id: null,
            team_members: null
        },
        modal_payload: {},
        show_level_linking_modal: false,
        show_objective_modal: false,
        show_objectives_tree: false,
        users: [],
        owner_query: '',
        show_levels_tree: false,
        levels_tree_payload: {},
        objectives_tree_payload: {}
    }),
    computed:{
        ...levelComputed,
        ...peopleComputed,
        ...profileComputed,
        getLevelForID(){
            let level = this.levels.find( level => {
                return level.id == this.params.level_id
            });
            if(level) {
                return level.name
            } 
            return 'No Level Linked'
        },
    },
    methods:{
        ...peopleMethods,
        onOverItem(item, value) {
            switch (item) {
            case "levelsDropdown":
                this.show_levels_tree = value
                this.$refs.levelsDropdown.visible = value;
              break;
          }
        },
        selectedOwner(item) {
            let email = item.split('|')[1];
            let email_string = email.trim();
            let user = this.all_users.find( item => {
                return item.email == email_string
            });
            this.params.user_id = user.id
        },
        populateList(){
            let users = [];
            this.all_users.forEach( user =>{
                // this.params.team_members.forEach(() => {

                // })
                users.push(`${user.name} | ${user.email}`)
                if(this.params.user_id != null && user.id == this.params.user_id) {
                    this.owner_query = `${user.name} | ${user.email}`
                }
            });
            this.users = users;
        },
        save(){
            this.$emit('saveTable', [this.params]);
            this.$nextTick(() => {
                this.$bvModal.hide('workGroupModal');
            })
        },
        handleLevelUpdateEvent(new_id) {
            this.params.level_id = new_id;
            this.onOverItem('levelsDropdown', false)
            let level = this.levels.find((lvl) => lvl.id == this.params.level_id);
            this.levels_tree_payload = { id: this.params.level_id, parent_id: level.parent_id, item_id: this.params.id, level_id: this.params.level_id};
        },
    },
    beforeDestroy() {
        this.modal_payload = {}
    },
    mounted(){
        if(this.payload.id) {
            this.params.id = this.payload.id
            this.params.name = this.payload.name
            this.params.user_id = this.payload.lead_user
            this.params.team_members = this.payload.team_members
            this.params.level_id = this.payload.level_id;
        } else {
            this.params.level_id = this.selected_level.id;
        }
        // this.$nextTick(() => {
            this.populateList()
        //})

        let level = this.levels.find((lvl) => lvl.id == this.params.level_id);
        this.levels_tree_payload = { id: this.params.level_id, parent_id: level.parent_id, item_id: this.params.id, level_id: this.params.level_id};
    },
}
</script>

<style>
/* #dropdownTree2 .dropdown-menu {
    max-width: 300%;
} */

#dropdownTree .dropdown-toggle {
    text-align: left;
}
#dropdownTree2 .dropdown-toggle {
    text-align: left;
}
</style>